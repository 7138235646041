import { createContext, type PropsWithChildren, useEffect, useRef, useState } from 'react';

import noop from 'lodash/noop';
import { useUserInfo } from '~/hooks/use-user-info';
import { useAuthedRequest } from '~/hooks/use-authed-request';
import { Player } from '~/types';

// Create Context Object
export const PlayerInfoContext = createContext<{
  playerInfo: Player | undefined;
  refetch: (callback?: () => void) => void;
}>({
  playerInfo: undefined,
  refetch: noop,
});

// Create a provider for components to consume and subscribe to changes
export const PlayerInfoProvider = ({ children }: PropsWithChildren) => {
  const userInfo = useUserInfo();
  const [playerInfo, setPlayerInfo] = useState<Player | undefined>();
  const { get } = useAuthedRequest(true);
  const shouldFetch = useRef(true);

  const fetch = (callback?: () => void) => {
    get(`/players/${userInfo.id}`)
      .then(({ data }) => {
        setPlayerInfo(data);
        callback?.();
      })
      .catch((error) => {
        console.error(error);
        callback?.();
      });
  };

  useEffect(() => {
    if (shouldFetch.current && userInfo?.id) {
      shouldFetch.current = false;
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <PlayerInfoContext.Provider
      value={{
        playerInfo,
        refetch: fetch,
      }}
    >
      {children}
    </PlayerInfoContext.Provider>
  );
};
